import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import { WaitlistForm } from '@/Waitlist-Form';

const Waitlist: Page = () => (
    <main>
        <DefaultLayout title="Dr. Nedeau Waitlist" about>
            <Seo
                title="About Us | Bloom La Vie Health"
                description="Dr. Christine Nedeau, MD, FAAFP is a board-certified family physician and the founder and CEO of Bloom La Vie Health. She moved to the Kansas City area in 2002 after completing her family medicine residency training in Phoenix, Arizona."
            />
            <WaitlistForm />
        </DefaultLayout>
    </main>
);

export default Waitlist;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';

const StyledWaitlistForm = styled.div`
    padding: 5em 0em 5em;
    position: relative;
    display: flex;
    justify-content: center;
    .alarm-clock {
        transform: scaleX(-1);
        filter: drop-shadow(32px 32px 64px rgba(3, 93, 125, 0.2))
            drop-shadow(-32px -32px 64px rgba(3, 93, 125, 0.2));
        border-radius: 16px;
        height: 600px;
    }
    textarea {
        border-radius: 6px;
        min-width: 325px;
        margin: 0.5em 0em 0em;
        max-width: 325px;
        min-height: 80px;
    }
    form {
        color: ${colors.white};
        position: absolute;
        margin: 1em;
        display: flex;
        flex-direction: column;

        padding: 2em 0em;
        width: 380px;
        border-radius: 64px;
        background: ${colors.blueGradient};
        > div {
            margin: 0.5em 1.5em;
        }
        button {
            align-self: start;
            margin: 1.25em 0em 0em 1.25em;
        }
        select {
            border-radius: 6px;
            border: none;
            appearance: none;
            width: 100%;
            padding: 1em;
            background-size: 1.3em;
            margin-top: 10px;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right 0.4rem center;
        }
        input {
            margin-top: 0.5em;
        }
        label {
            font-weight: 600;
            display: flex;
            flex-direction: column;
        }
        > h5 {
            margin: 0;
            text-align: center;
            font-size: 36px;
        }
    }

    @media ${device.tablet} {
        position: relative;
        bottom: 6em;
        textarea {
            border-radius: 6px;
            min-width: 385px;
            margin: 0.5em 0em 0em;
            max-width: 385px;
            min-height: 110px;
            min-height: 80px;
        }
        padding: 4em 0em 0em;
        .alarm-clock {
            height: 700px;
            width: 800px;
        }
        form {
            min-width: 500px;
            top: 6em;

            padding: 2em;
            height: 630px;
        }
    }
    @media ${device.laptop} {
        margin-top: 72px;
        .alarm-clock {
            border-radius: 64px;
            margin-right: 0em;
        }

        form {
            margin: 0 auto;
            padding: 3em 2em 4em;
        }
    }
`;

export const WaitlistForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledWaitlistForm>
            <StaticImage
                className="alarm-clock"
                src="../images/experience.jpg"
                alt="experience"
                placeholder="none"
                quality={100}
            />
            <form onSubmit={onSubmit}>
                <h5>Join Waitlist</h5>
                <div>
                    <label>
                        Name *
                        <input
                            required
                            type="text"
                            name="waitlist-name"
                            id="waitlist-name"
                            placeholder="John Doe"
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Phone *
                        <input
                            required
                            type="tel"
                            name="waitlist-phone"
                            id="waitlist-phone"
                            placeholder="(222) 222 2222"
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Email address *
                        <input
                            required
                            type="email"
                            name="waitlist-email"
                            id="waitlist-email"
                            placeholder="example@gmail.com"
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Location
                        <input name="waitlist-location" type="text" id="text" placeholder="phone" />
                    </label>
                </div>
                <div>
                    <label htmlFor="typeOfCare">Type of Care</label>
                    <select name="waitlist-care" id="type of caree">
                        <option value="primaryCare">Primary Care</option>
                        <option value="functionalCare">Functional Care</option>
                    </select>
                </div>
                <Button disabled={submitState === 'thanks!'} color="white" type="submit">
                    {' '}
                    <span>
                        {' '}
                        {submitState === 'submitting' ? 'Sending message...' : 'Join Waitlist'}
                        {submitState === 'submitted' && 'Thanks!'}
                    </span>{' '}
                    <StaticImage
                        src="../images/svgs/arrow-right-white.svg"
                        placeholder="none"
                        quality={100}
                        alt="white arrow point right"
                    />
                </Button>
            </form>
        </StyledWaitlistForm>
    );
};
